@import '~antd/dist/antd.less';

/* 
Página com todas as variáveis
https://github.com/ant-design/ant-design/blob/4.x-stable/components/style/themes/default.less
 */

@primary-color: #337ab7;
@layout-header-background: #337ab7;
@layout-header-color: white;
@layout-footer-background: #337ab7;
@layout-header-height: 64px;
@layout-footer-height: 48px;
@layout-sider-background: #ffffff;
@layout-trigger-background: #274060;
@layout-trigger-height: 120px;
@layout-body-background: #eeeeee;
@border-color-base: rgba(39, 64, 96, 0.15);
@border-color-split: rgba(39, 64, 96, 0.1);
@table-header-bg: #e0e4e8;
@text-color: #274060;
@heading-color: #274060;
@input-bg: #ffffff;
@select-background: @input-bg;
@select-dropdown-bg: @input-bg;
@picker-bg: @input-bg;
@picker-bg: @input-bg;
@calendar-bg: @input-bg;
@progress-remaining-color: rgba(39, 64, 96, 0.2);
@font-size-base: 16px;
@height-base: 36px;
@height-lg: 34px;
@height-sm: 34px;

/* Para Firefox (e navegadores que suportam scrollbar-color) */
* {
  scrollbar-color: rgba(@primary-color, 0.5) transparent; /* Cor do thumb e do track */
  scrollbar-width: thin; /* Estilo fino para Firefox */
}

/* Para navegadores WebKit (Chrome, Edge, etc.) */
*::-webkit-scrollbar {
  width: 8px; /* Largura do scrollbar vertical */
  height: 8px; /* Altura do scrollbar horizontal */
}

*::-webkit-scrollbar-thumb {
  background-color: pink; /* Cor do thumb */
  border-radius: 4px; /* Bordas arredondadas */
}

*::-webkit-scrollbar-track {
  background: transparent; /* Cor do fundo */
}

:where(.ant-dropdown-trigger) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  aspect-ratio: 1/1;
  cursor: pointer;
}

:where(.ant-dropdown-trigger) > * {
  font-size: 36px;
  cursor: pointer;
}

@header-height: 64px;
@footer-height: 48px;
@sider-width: 250px;
@sider-collapsed-width: 0;

.layout {
  height: 100vh;
  overflow: hidden;
}

.header {
  height: @header-height;
  left: 0;
  right: 0;
  position: fixed;
  top: 0;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(@text-color, 0.9);
  -moz-box-shadow: 0px 2px 5px 0px rgba(@text-color, 0.9);
  box-shadow: 0px 2px 5px 0px rgba(@text-color, 0.9);
  width: 100%;
  z-index: 1000;
}

.content-layout {
  display: flex;
  justify-content: center; // Centraliza o Content no espaço disponível
  margin-top: @header-height;
  margin-bottom: @footer-height;
  height: calc(100vh - @header-height - @footer-height);
  overflow-y: auto; // Garante que o scroll apareça na lateral direita da tela
  overflow-x: hidden;
  transition: margin-left 0.3s ease;
  margin-left: @sider-width; // Garante que o Content não fique atrás do Sider
}

.content-layout {
  /* Modificação quando o pai tem a classe 'content-layout-with-sider-collapsed' */
  &.content-layout-with-sider-collapsed {
    margin-left: 0;
  }
}

.sider {
  position: fixed;
  top: @header-height;
  bottom: @footer-height;
  left: 0;
  width: @sider-width;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;
  -webkit-box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3) !important;
  z-index: 100;
}

.sider-collapsed {
  width: @sider-collapsed-width;
}

.content {
  height: 100%;
  width: 100%;
  margin: auto;
  padding-bottom: 16px;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  flex-grow: 1;
  overflow-y: auto; // Scroll vertical dentro do Content
  overflow-x: hidden;
}

.footer {
  align-content: center;
  color: white;
  bottom: 0;
  height: @footer-height;
  left: 0;
  padding: 0;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 5;
}

.ant-input[disabled] {
  background-color: #d1d1d1;
}

.ant-btn[disabled] {
  background-color: #d1d1d1;
}

.ant-back-top {
  right: 25px;
  bottom: 25px;
}

.ant-back-top-content {
  background-color: #607d8b;
  color: white !important;
}

.ant-btn-primary:hover {
  background-color: @text-color;
}

.expanded-row-siz .ant-table-cell {
  background-color: #eeeeee !important;
}

/* styles.global.css */
.inverted-button {
  background-color: white !important; /* Fundo branco */
  border-color: #337ab7 !important; /* Borda com a cor primary */
  color: #337ab7 !important; /* Texto e ícones com a cor primary */
}

.inverted-button:hover {
  border-color: #5695c4 !important; /* Cor da borda ao passar o mouse */
  color: #5695c4 !important; /* Cor do texto e ícones ao passar o mouse */
}

.ant-table-title {
  background-color: #607d8b;
}

.ant-table.ant-table-small > .ant-table-title {
  padding: 6px 24px !important;
  padding-right: 6px !important;
}

.ant-table-title .ant-typography {
  color: white !important;
  line-height: unset;
  margin-bottom: 0;
  margin-left: 8px;
  margin-top: 0;
}

/* Estilo para botões dentro de ant-table-title, exceto botões danger */
.ant-table-title .ant-btn:not(.ant-btn-dangerous) {
  background-color: white !important; /* Fundo branco */
  border-color: #337ab7 !important; /* Borda com a cor primary */
  color: #337ab7 !important; /* Texto e ícones com a cor primary */
}

/* Estilo para hover dos botões dentro de ant-table-title, exceto botões danger */
.ant-table-title .ant-btn:not(.ant-btn-dangerous):hover {
  background-color: #f0f0f0 !important; /* Cor de fundo ao passar o mouse */
  border-color: #286090 !important; /* Cor da borda ao passar o mouse */
  color: #286090 !important; /* Cor do texto e ícones ao passar o mouse */
}

.ant-table-cell {
  padding: 6px 8px !important;
  padding-right: 6px !important;
}

.sigCanvas {
  width: 100%;
  height: 100%;
}

.loginPanel {
  //border: 1px solid;
  background-color: white;
  display: flex;
  min-height: 350px;
  width: 600px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 15px;
}

.ant-layout-sider-trigger-custom-wrapper {
  height: 64px;
  left: 0;
  width: 48px;
  top: 0;
  position: fixed;
  z-index: 5000;
}

.ant-layout-sider-trigger-custom {
  display: flex;
  height: 100%;
  width: 100%;
}

.drawer-dev-tools {
  height: 25px;
  width: 60px;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 1001;
  margin: auto;
  background-color: #f7f5f3;
  text-align: center;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.drawer-steps {
  height: 50px;
  width: 60px;
  top: 100px;
  right: 0;
  position: fixed;
  z-index: 1;
  margin: auto;
  color: white;
  font-size: 32px;
  background-color: @primary-color;
  text-align: center;
  align-content: center;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  transition: right 0.3s ease;
}

.drawer-steps.open {
  right: 378px;
  z-index: 1200;
}

@keyframes badgeMove {
  0%,
  5% {
    transform: translateX(0); /* Posição inicial */
  }
  10% {
    transform: translateX(-5px); /* Mover para a esquerda */
  }
  15% {
    transform: translateX(5px); /* Mover para a direita */
  }
  20% {
    transform: translateX(-5px); /* Mover para a esquerda novamente */
  }
  25% {
    transform: translateX(5px); /* Mover para a direita novamente */
  }
  30% {
    transform: translateX(-5px); /* Retorna à posição inicial */
  }
  35% {
    transform: translateX(0); /* Posição inicial */
  }

  100% {
    transform: translateX(0); /* Posição inicial */
  }
}

.animated-badge {
  position: relative;
  display: inline-flex; /* Mantém o Avatar e Badge na mesma linha */
}

.animated-badge .ant-scroll-number {
  position: absolute;
  bottom: 0;
  right: 0;
  animation: badgeMove 6s ease-in-out infinite; /* Animação com movimento e pausa */
  will-change: transform;
}

.anticon anticon-menu-fold trigger {
  height: 100%;
}

.ant-card-body {
  overflow-wrap: break-word;
}

.ant-card-head-title {
  white-space: break-spaces;
}

.ant-checkbox-group-item {
  display: flex;
  flex-direction: row;
}

.ant-checkbox-inner,
.ant-checkbox-input {
  transform: scale(1.1);
}

.ant-form-item-has-error {
  border: 1px solid !important;
  border-color: red !important;
  background-color: #fceddf !important;
}

.formItemInvisible {
  .ant-row {
    .ant-col {
      .ant-form-item-control-input {
        min-height: 0;
      }
    }
  }
}

.anticon.ant-input-clear-icon-hidden {
  visibility: visible !important;
}

.ant-message {
  top: 35px;
}

.ant-message-custom-content {
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 400px;
  top: 74;
}

.ant-modal-wrap.ant-modal-wrap-centerized {
  display: flex;
}

.ant-modal-full-screen .ant-modal-content {
  box-shadow: none !important;
}

.ant-popover-inner-content {
  padding: 0 4px;
}

.ant-select-item-option-content {
  white-space: break-spaces;
  word-break: break-word;
}

.ind {
  width: 20px;
  height: 20px;
  list-style: none;
  border: 4px solid #f5f5f5;
  transform: rotate(135deg);
  display: inline-block;
  cursor: pointer;
  position: relative;
  z-index: 100;
}

.ind.active {
  background-color: #000;
  transition: 500ms 200ms;
}

.ind:not(:last-child) {
  margin-right: 1.4rem;
}

.nav_btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  z-index: 4;
  border: none;
}

.nav_btn_left {
  left: 0;
}
.nav_btn_right {
  right: 0;
}

// Limita a aparição do botão de instalação apenas em browsers com esta capacidade
.install-app-btn-container {
  display: none;
}

.ant-steps-horizontal {
  overflow-y: hidden;
  overflow-x: scroll;
}

.steps-content {
  min-height: 200px;
  margin-top: 16px;
  padding-top: 80px;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
}

.table-break-points td,
.table-break-points th {
  border: 1px solid;
  min-width: 20px;
}

/* Container principal */
.container {
  background-color: white !important;
  display: flex;
  flex-wrap: nowrap; /* Permite que a segunda linha quebre quando necessário */
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  overflow: hidden;
  padding-right: 8px;
  padding-left: 8px;
  box-shadow: 0 0 4px rgba(96, 125, 139, 0.4) !important;
  z-index: 1000;
}

/* Linha superior */
.top-row {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
}

/* Tabs não devem ocupar todo o espaço da .container, apenas o necessário dentro da .top-row */
.tabs-wrapper {
  flex-grow: 1;
  min-width: 0;
}

.tabs-wrapper .ant-tabs-nav {
  margin: 0;
}

/* Botões 3 e 4 na segunda linha abaixo de 768px */
.extra-buttons {
  display: flex;
  gap: 8px;
}

/* Botões de navegação personalizados */
.tabs-nav-btn {
  background-color: white;
  border: none;
  padding: 8px;
  font-size: 16px;
  z-index: 101;
}

.tabs-nav-btn:focus {
  outline: none;
}

#tbodyGraf.tbodyPrint,
#tbodyGraf.tbodyPrint > tr,
#tbodyGraf.tbodyPrint > tr > td {
  display: block; /* Sobrescreve o comportamento de tabela */
}

#tbodyGraf.tbodyPrint td > table {
  display: table !important;
}

#tbodyGraf.tbodyPrint td > table > tbody {
  display: table-row-group; /* Restaurando o comportamento do tbody interno */
}

#tbodyGraf.tbodyPrint td > table > tbody > tr {
  display: table-row; /* Restaurando o comportamento do tr interno */
}

#tbodyGraf.tbodyPrint td > table > tbody > tr > td {
  display: table-cell; /* Restaurando o comportamento do td interno */
}

/* Esconder o conteúdo de impressão na tela */
.print-content {
  display: none;
}

@media screen and (max-width: 768px) {
  .custom-modal-content {
    box-shadow: none;
    background: none;
  }

  .container {
    flex-direction: column;
  }

  .top-row {
    flex-direction: row; /* Mantém Botão 1, Tabs e Botão 2 juntos */
    max-width: none;
  }

  .tabs-wrapper {
    max-width: 100%; /* Tabs ocupam todo o espaço disponível na primeira linha */
  }

  .extra-buttons {
    justify-content: flex-end;
    width: 100%;
  }

  .loginPanel {
    max-width: 350px;
    overflow: auto;
  }
}

.marcadagua {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  font-weight: bold;
  opacity: 0.3;
  white-space: nowrap;
  pointer-events: none; /* Permite interação com elementos abaixo */
  user-select: none; /* Impede que o texto da marca d'água seja selecionado */
  font-size: min(20vw, 20vh); /* Ajusta dinamicamente o tamanho */
  z-index: 100;
}

.highlight-invalid-row {
  background-color: #fabdb7;
  color: white;
}

/* Sobrescrever o estilo de hover padrão do Ant Design */
.ant-table-tbody > tr.highlight-invalid-row:hover > td {
  background-color: #e79690 !important; /* Vermelho mais intenso */
}

@media print {
  body {
    font-size: 5px;
    overscroll-behavior: hidden;
  }

  @page {
    size: A4;
    margin-top: 10mm;
    margin-right: 15mm;
    margin-bottom: 10mm;
    margin-left: 15mm;
  }

  .dev-tools {
    display: none !important;
  }

  .governoMT {
    margin-bottom: 12px;
  }

  .hide-on-print {
    display: none !important;
  }

  .affixTabs {
    display: none !important;
  }

  .ant-descriptions-item-content,
  .ant-descriptions-item-label {
    min-height: 0 !important;
    line-height: 80%;
  }

  .tabs-header-container {
    display: none; /* Ocultar o cabeçalho na impressão */
  }

  .tabs-content {
    display: none; /* Ocultar o conteúdo das Tabs na impressão */
  }

  .print-content {
    display: block !important; /* Mostrar conteúdo concatenado */
  }

  span {
    font-size: 11px;
    min-height: 0 !important;
  }

  h1.ant-typography {
    font-size: 28px;
  }
  h2.ant-typography {
    font-size: 24px;
  }
  h3.ant-typography {
    font-size: 18.72px;
  }
  h4.ant-typography {
    font-size: 16px;
  }
  h5.ant-typography {
    font-size: 14px;
  }

  .ant-card-small,
  .ant-card-head {
    min-height: 0 !important;
  }

  .ant-card-head-title {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .cabecalhoGovernoMT {
    font-size: 14px;
  }

  .ant-layout,
  .ant-layout-content,
  .ant-layout-has-sider,
  .ant-layout-footer {
    height: 0;
    visibility: hidden;
  }
  .ant-layout-header,
  .ant-layout-footer,
  .ant-layout-sider,
  .ant-layout-sider-dark,
  .ant-layout-sider-trigger-custom-wrapper,
  .printButton,
  .sider-wrapper {
    display: none !important;
  }
  .newPage {
    page-break-inside: avoid;
    display: block;
  }

  .ant-layout {
    background-color: #ffffff;
  }
}

/* Overlay cobrindo toda a tela */
.custom-modal-overlay {
  position: fixed !important; /* Garante que fique fixo sobre toda a tela */
  top: 0 !important;
  left: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  background-color: rgba(255, 255, 255) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  opacity: 0 !important;
  visibility: hidden !important;
  transition: opacity 0.5s ease, visibility 0.5s ease !important;
  z-index: 1000000 !important; /* Forçando a prioridade */
}

/* Mostrar a overlay */
.custom-modal-overlay.show {
  opacity: 1 !important;
  visibility: visible !important;
}

/* Estilo do conteúdo */
.custom-modal-content {
  min-width: 400px;
  color: white !important;
  position: relative !important; /* Garante que o conteúdo também tenha um posicionamento válido */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(0.8);
  transition: transform 0.5s ease, opacity 0.5s ease;
  z-index: 1000001 !important; /* Garante que o conteúdo esteja acima do overlay */
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-around;
  align-items: center;
}

.Formularios_anexos_fver {
  max-width: 95vw !important;
}

/* Efeito de zoom na entrada */
.custom-modal-overlay.show .custom-modal-content {
  transform: scale(1);
}

/* Classe para desabilitar o scroll */
.body-no-scroll {
  overflow: hidden;
}

/* Garantir que o botão de remover fique visível sempre */
.ant-upload-list-item-card-actions-btn {
  opacity: 1 !important;
  visibility: visible !important;
  pointer-events: auto !important;
}

.bounce {
  animation: bounce 2s ease-in-out infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.spin {
  animation: spin 10s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-collapse-container {
  width: 100%;
  position: relative;
}

.collapse-button {
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 35px;
  border-radius: 15px; /* Bordas arredondadas para formar semi-círculos */
  border: 1px solid #607d8b;
  color: #607d8b;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: left;
}

.collapse-button.open {
  width: 100%; /* Expande até a margem direita */
  background-color: #607d8b;
  border-radius: 2px;
  color: white;
}

.custom-collapse {
  width: 100%;
  border: none;
  background-color: transparent;
}

.custom-collapse.open {
  background-color: #fff;
}

.custom-collapse .ant-collapse-item {
  border: none !important;
}

.custom-collapse .ant-collapse-content {
  border: none !important;
}

.custom-collapse .ant-collapse-header {
  display: none; /* Oculta o header padrão do Collapse */
}

/* Sobrescreve o cursor do header do Panel */
.custom-collapse .ant-collapse-item-disabled > .ant-collapse-header {
  cursor: default !important;
}
